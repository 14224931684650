/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import {
  useTabs,
  useTab,
  useTabPanels,
  TabsProvider,
  UseTabsProps,
  useTabPanel,
  useTabList,
  UseTabListProps,
} from './use-tabs';
import { calculateClassName, Select } from '../';
import { useViewport } from '@expressable/utils';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

type DivRef = HTMLDivElement;

export interface TabsProps extends UseTabsProps {
  children: React.ReactNode;
}

export const Tabs = React.forwardRef<DivRef, TabsProps>(function Tabs(props, ref) {
  const { htmlProps, ...ctx } = useTabs(props);
  const context = React.useMemo(() => ctx, [ctx]);
  return (
    <TabsProvider value={context}>
      <div ref={ref} {...htmlProps} />
    </TabsProvider>
  );
});

// Custom styles for react-select
//
// Auto-width attributes from https://github.com/JedWatson/react-select/issues/4201
const selectStyles = {
  indicatorSeparator: provided => {
    // Remove vertical bar left to the indicator
    provided.display = 'none';
    return provided;
  },
  dropdownIndicator: provided => {
    // The default padding is 8px in every side
    provided.paddingLeft = '4px';
    provided.minWidth = '155px';
    return provided;
  },
  container: provided => {
    // Auto-width
    provided.display = 'inline-block';
    return provided;
  },
  singleValue: provided => {
    // Auto-width
    delete provided.position;
    provided.maxWidth = 'none';
    provided.transform = 'none';
    provided.color = '#5456ca';
    return provided;
  },
  control: provided => {
    // Remove background color (make transparent)
    provided.backgroundColor = 'transparent';

    // Remove focus border
    provided.borderStyle = 'none';
    delete provided['boxShadow'];

    return provided;
  },
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faCaretDown} color="#5456ca" />
  </components.DropdownIndicator>
);

export const TabList = React.forwardRef<DivRef, UseTabListProps>(function TabList({ ...props }, ref) {
  const { viewportWidth } = useViewport();
  const { SelectOptions, setSelectedIndex, selectedIndex } = useTabList(props);

  const selectEl = useRef(null);
  useEffect(() => {
    if (selectEl.current) {
      const selectElSelectedIndex = selectEl.current.state.value.value;
      if (selectElSelectedIndex !== selectedIndex) {
        const selectElSelectOption = selectEl.current.select.selectOption;
        selectElSelectOption(SelectOptions[selectedIndex]);
      }
    }
  });

  return (
    <>
      <div ref={ref} {...props}>
        {viewportWidth > 1305 ? (
          <div className="block">
            <div className="border-b border-gray-300">
              <nav className="-mb-px flex">{props.children}</nav>
            </div>
          </div>
        ) : (
          <div className="flex items-center border-b border-gray-300">
            <span className="text-sm text-gray-400">View</span>
            <Select
              onChange={(selectedOption: { [key: string]: string }) => {
                setSelectedIndex(Number(selectedOption.value));
              }}
              ref={selectEl}
              defaultValue={SelectOptions[selectedIndex]}
              options={SelectOptions}
              aria-label="Selected tab"
              spacing="tight"
              styles={selectStyles}
              components={{ DropdownIndicator }}
              isSearchable={false}
              className="
              text-base
              leading-6
              border-gray-300
              focus:outline-none
              focus:border-blue-300
              transition
              ease-in-out
              duration-150
            "
            ></Select>
          </div>
        )}
      </div>
    </>
  );
});

type AnchorRef = HTMLAnchorElement;
export const Tab = React.forwardRef<AnchorRef, React.ComponentPropsWithoutRef<'a'>>(function Tab(
  { className, ...props },
  ref,
) {
  const tabProps = useTab({ ...props, ref });

  const selected = `px-1 py-4 ml-6 text-sm font-medium text-indigo-600 border-b-2 border-indigo-500 whitespace-nowrap leading-5 focus:outline-none focus:text-indigo-800 focus:border-indigo-700`;
  const inactive = `px-1 py-4 ml-6 text-sm font-medium text-gray-500 border-b-2 border-transparent whitespace-nowrap leading-5 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300`;

  return (
    <a
      ref={ref}
      className={calculateClassName(tabProps['aria-selected'] ? selected : inactive, className)}
      {...tabProps}
    />
  );
});

export const TabPanels = React.forwardRef<DivRef, React.ComponentPropsWithoutRef<'div'>>(function TabPanels(
  props,
  ref,
) {
  const panelsProps = useTabPanels(props);
  return <div ref={ref} {...panelsProps}></div>;
});

export const TabPanel = React.forwardRef<DivRef, React.ComponentPropsWithoutRef<'div'>>(function TabPanel(
  { className, ...props },
  ref,
) {
  const { isSelected, ...panelProps } = useTabPanel({ ...props, ref });

  const [wasOpened, setWasOpened] = useState(isSelected);

  useEffect(() => {
    if (isSelected) {
      setWasOpened(true);
    }
  }, [isSelected]);

  if (!isSelected && !wasOpened) {
    // if tab is not selected AND was never opened yet, don't render anything
    return null;
  }

  // if tab was opened but it is not selected, render the component applying hidden style
  return (
    <div {...panelProps} className={calculateClassName(isSelected ? `block` : `hidden`, className)} ref={ref}></div>
  );
});
